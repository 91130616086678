import Billboard from "./Billboard";
import FacePng1 from "./assets/face-1.png";
import FacePng2 from "./assets/face-2.png";
import FacePng3 from "./assets/face-3.png";
import FacePng4 from "./assets/face-4.png";
import FacePng5 from "./assets/face-5.png";
import FacePng6 from "./assets/face-6.png";

class FaceBillboard extends Billboard {
  constructor(params) {
    super(params);

    this.maximumLength = (Math.random() + 0.25) * 1.25;
    this.chooseRandomFace();
  }

  chooseRandomFace() {
    const faces = [
      { width: 530, height: 754, textureUrl: FacePng1 },
      { width: 355, height: 470, textureUrl: FacePng2 },
      { width: 236, height: 299, textureUrl: FacePng3 },
      { width: 696, height: 1013, textureUrl: FacePng4 },
      { width: 208, height: 287, textureUrl: FacePng5 },
      { width: 431, height: 499, textureUrl: FacePng6 },
    ];

    const face = faces[Math.floor(Math.random() * faces.length)];

    this.width = face.width;
    this.height = face.height;
    this.textureUrl = face.textureUrl;
  }
}

export default FaceBillboard;
