import {
  Scene,
  PerspectiveCamera,
  Vector3,
  TextureLoader,
  FontLoader,
  WebGLCubeRenderTarget,
} from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { TTFLoader } from "three/examples/jsm/loaders/TTFLoader";
import { randomVector3 } from "./utils";
import MinecraftBackgroundJpg from "./assets/minecraft-background.jpg";
import ChocolateCoveredRaindropsBoldFont from "./assets/Chocolate Covered Raindrops BOLD.ttf";
import Cake from "./Cake";
import FaceBillboard from "./FaceBillboard";
import BulgingText from "./BulgingText";
import ItemFactory from "./ItemFactory";

class FaceZone {
  constructor({ renderer }) {
    this.renderer = renderer;
    this.animatable = [];

    this.makeScene();
    this.addCake();
    this.addFaces();
    this.addItems();
    this.loadFont(() => {
      this.addText();
    });
  }

  makeScene() {
    this.scene = new Scene();

    this.camera = new PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );

    this.camera.position.y = -5;
    this.camera.position.z = 10;

    this.addBackground();
    this.makeOrbitControls();
  }

  updateCameraAspect() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
  }

  addBackground() {
    const loader = new TextureLoader();
    const texture = loader.load(MinecraftBackgroundJpg, () => {
      const renderTarget = new WebGLCubeRenderTarget(texture.image.height);
      renderTarget.fromEquirectangularTexture(this.renderer, texture);
      this.scene.background = renderTarget;
    });
  }

  makeOrbitControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.target = new Vector3(0, 0, 0);
    this.controls.enablePan = false;
    this.controls.minDistance = 0.25;
    this.controls.maxDistance = 30;
    this.controls.zoomSpeed = 0.75;
    this.controls.autoRotate = true;
  }

  addCake() {
    const cake = new Cake();
    cake.mesh.position.set(0, 0, 0);
    this.animatable.push(cake);
    this.scene.add(cake.mesh);
  }

  addFaces() {
    const numberOfFaces = 200;

    for (let i = 0; i < numberOfFaces; i++) {
      const face = new FaceBillboard();
      face.sprite.position.copy(randomVector3());
      this.scene.add(face.sprite);
    }
  }

  addItems() {
    new ItemFactory().addToScene(this.scene);
  }

  loadFont(onLoad) {
    const loader = new TTFLoader();

    loader.load(ChocolateCoveredRaindropsBoldFont, (fnt) => {
      this.font = new FontLoader().parse(fnt);
      onLoad();
    });
  }

  addText() {
    const seconds = new Date().setHours(0) - new Date("1997-02-02");
    const days = Math.round(seconds / (1000 * 3600 * 24)).toLocaleString();

    const blue = 0x00ceff;
    const pink = 0xed57c0;
    const yellow = 0xffff00;
    const darkYellow = 0x303100;
    const green = 0x00ff00;
    const orange = 0xffa500;

    [
      {
        timeout: 0,
        text: "Welcome\nto the\nKatelyn Zone",
        colors: [blue, pink],
        startingPosition: [0, 0, 0],
      },
      {
        timeout: 0,
        text: "what are you\ndoing up here?",
        colors: [pink, blue],
        size: 0.2,
        randomizePosition: false,
        startingPosition: [0, 27, 0],
      },
      { timeout: 10, text: "wow!", colors: [orange, green] },
      {
        timeout: 20,
        text: "Katelyn\nrulz ok!",
        colors: [yellow, darkYellow],
      },
      { timeout: 30, text: "nice!", colors: [green, orange] },
      { timeout: 40, text: "lol!", colors: [pink, blue] },
      { timeout: 50, text: "*happy fox noises*", colors: [pink, green] },
      { timeout: 60, text: "ftp!", colors: [green, orange] },
      { timeout: 70, text: "acab!", colors: [pink, blue] },
      {
        timeout: 80,
        text: "happy birthday, btw!",
        colors: [yellow, darkYellow],
      },
      {
        timeout: 90,
        text: `you are now\n${days} days old`,
        colors: [pink, blue],
      },
      {
        timeout: 144,
        text: "I hope you liked\nthe song lol!",
        colors: [pink, blue],
      },
    ].forEach(
      ({
        timeout,
        text,
        colors,
        size,
        randomizePosition,
        startingPosition,
      }) => {
        const onLoad = (bt) => {
          window.setTimeout(() => {
            this.scene.add(bt.mesh);
          }, timeout * 1000);
        };

        const bulgingText = new BulgingText({
          text,
          font: this.font,
          frontColor: colors[0],
          sideColor: colors[1],
          size,
          camera: this.camera,
          onLoad,
          randomizePosition,
          startingPosition,
        });

        this.animatable.push(bulgingText);
      }
    );
  }

  animate() {
    this.controls.update();
    this.animatable.forEach((a) => a.animate());
  }
}

export default FaceZone;
