import { Vector3 } from "three";

function getRandomIntInclusive(min, max) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random#getting_a_random_integer_between_two_values_inclusive
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}

function randomVector3(min = -10, max = 10) {
  const x = getRandomIntInclusive(min, max);
  const y = getRandomIntInclusive(min, max);
  const z = getRandomIntInclusive(min, max);

  return new Vector3(x, y, z);
}

export { randomVector3 };
