import DieAnywhereElseMp3 from "./die-anywhere-else.mp3";

const addAudio = () => {
  const updateIcon = () => {
    let label;

    if (audioEl.paused) {
      label = "Play Music";
      pauseButton.innerText = "🔈";
    } else {
      label = "Mute Music";
      pauseButton.innerText = "🔇";
    }

    pauseButton.ariaLabel = label;
    pauseButton.title = label;
  };

  const getMutedParam = () => {
    const params = new URL(document.location).searchParams;
    const mutedParam = params.get("muted");
    return mutedParam !== null;
  };

  const autoplayEnabled = !getMutedParam();

  const onClickDocument = () => {
    if (audioEl.paused && neverToggled & autoplayEnabled) {
      audioEl.play();
    }
  };

  const onTouchDocument = (e) => {
    if (e.target !== pauseButton) {
      onClickDocument();
    }
  };

  let neverToggled = true;
  const onClickToggle = () => {
    neverToggled = false;
    if (audioEl.paused) {
      audioEl.play();
    } else {
      audioEl.pause();
    }

    updateIcon();
  };

  const pauseButton = document.createElement("button");
  pauseButton.classList.add("mute-button");
  pauseButton.addEventListener("click", onClickToggle, false);

  document.body.append(pauseButton);

  const audioEl = new Audio(DieAnywhereElseMp3);
  audioEl.autoplay = autoplayEnabled;
  audioEl.addEventListener("play", updateIcon, false);
  audioEl.addEventListener("pause", updateIcon, false);

  document.addEventListener("touchend", onTouchDocument, false);
  document.addEventListener("click", onClickDocument, false);

  updateIcon();
};

export default addAudio;
