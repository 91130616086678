import { WebGLRenderer } from "three";
import "./index.css";
import FaceZone from "./scenes/FaceZone";
import addAudio from "./addAudio";

const renderer = new WebGLRenderer({ antialias: true });
document.body.appendChild(renderer.domElement);

const faceZone = new FaceZone({ renderer });

function animate() {
  window.requestAnimationFrame(animate);
  faceZone.animate();
  renderer.render(faceZone.scene, faceZone.camera);
}
animate();

function onResize() {
  renderer.setSize(window.innerWidth, window.innerHeight);
  faceZone.updateCameraAspect();
}
onResize();
window.addEventListener("resize", onResize);

addAudio();
