import { TextureLoader, SpriteMaterial, Sprite, NearestFilter } from "three";
class Billboard {
  constructor() {
    this.maximumLength = 1;
  }

  get sprite() {
    if (!this._sprite) {
      this._sprite = this.buildSprite();
    }

    return this._sprite;
  }

  buildSprite() {
    const texture = new TextureLoader().load(this.textureUrl);
    texture.magFilter = NearestFilter;
    const material = new SpriteMaterial({ map: texture });
    const sprite = new Sprite(material);

    let scaleX = (this.width / this.height) * this.maximumLength;
    let scaleY = this.maximumLength;

    if (this.width > this.height) {
      scaleX = this.maximumLength;
      scaleY = (this.height / this.width) * this.maximumLength;
    }

    sprite.scale.set(scaleX, scaleY);
    return sprite;
  }
}

export default Billboard;
