import { randomVector3 } from "./utils";
import Billboard from "./Billboard";
import SpaceshipBillboardPng from "./assets/spaceship.png";
import MacaroniPng from "./assets/macaroni.png";
import SkatePng from "./assets/skate.png";
import FlameInTheFloodPng from "./assets/flame-in-the-flood.png";
import PsychonautsBadgePng from "./assets/psychonauts-badge.png";
import FirewatchPng from "./assets/firewatch.png";
import SansPng from "./assets/sans.png";

class ItemFactory {
  constructor() {
    this.items = [
      [SpaceshipBillboardPng, 1159, 400],
      [MacaroniPng, 193, 381],
      [SkatePng, 754, 789],
      [FlameInTheFloodPng, 146, 146],
      [PsychonautsBadgePng, 357, 305],
      [FirewatchPng, 512, 512],
      [SansPng, 32, 30],
    ];
  }

  addToScene(scene) {
    this.items.forEach(([textureUrl, width, height]) => {
      const item = new Billboard();
      item.textureUrl = textureUrl;
      item.width = width;
      item.height = height;

      item.sprite.position.copy(randomVector3());
      scene.add(item.sprite);
    });
  }
}

export default ItemFactory;
