import {
  TextureLoader,
  NearestFilter,
  Vector3,
  BoxGeometry,
  MeshBasicMaterial,
  Mesh,
  Math,
} from "three";
import CakeTopPng from "./assets/cake-top.png";
import CakeBottomPng from "./assets/cake-bottom.png";
import CakeSidePng from "./assets/cake-side.png";
import CakeInnerPng from "./assets/cake-inner.png";

class Cake {
  constructor() {
    this.amountLeft = 7 / 7;

    const scale = 5;
    this.mesh.scale.set(scale, scale, scale);

    this.startingY = -8;
    this.y = this.startingY;
    this.amountToMove = 0.005 * scale;
    const distanceToHover = 0.25 * scale;
    this.minY = this.startingY - distanceToHover / 2;
    this.maxY = this.startingY + distanceToHover / 2;
  }

  get mesh() {
    if (!this._mesh) {
      this._mesh = this.buildMesh();
    }

    return this._mesh;
  }

  buildMesh() {
    const loader = new TextureLoader();

    const topTexture = loader.load(CakeTopPng);
    const bottomTexture = loader.load(CakeBottomPng);
    const sideTexture = loader.load(CakeSidePng);
    const innerTexture = loader.load(CakeInnerPng);

    topTexture.magFilter = NearestFilter;
    bottomTexture.magFilter = NearestFilter;
    sideTexture.magFilter = NearestFilter;
    innerTexture.magFilter = NearestFilter;

    const width = 14 / 16;
    const height = 0.5;
    const depth = width * this.amountLeft;

    const geometry = new BoxGeometry(width, height, depth);
    const materials = [
      new MeshBasicMaterial({ map: sideTexture }),
      new MeshBasicMaterial({ map: sideTexture }),
      new MeshBasicMaterial({ map: topTexture }),
      new MeshBasicMaterial({ map: bottomTexture }),
      new MeshBasicMaterial({ map: innerTexture }),
      new MeshBasicMaterial({ map: sideTexture }),
    ];
    return new Mesh(geometry, materials);
  }

  animate() {
    this.y += this.amountToMove;

    if (this.y >= this.maxY || this.y <= this.minY) {
      this.amountToMove = -this.amountToMove;
    }

    this.mesh.position.y = this.y;
    this.mesh.rotateOnAxis(new Vector3(0, 1, 0), Math.degToRad(0.5));
  }
}

export default Cake;
