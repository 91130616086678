import { TextGeometry, Mesh, MeshBasicMaterial, Vector3 } from "three";
import { randomVector3 } from "./utils";

class BulgingText {
  constructor({
    text,
    font,
    frontColor,
    sideColor,
    size = 1,
    camera,
    onLoad,
    randomizePosition = true,
    startingPosition,
  }) {
    this.text = text;
    this.font = font;
    this.frontColor = frontColor;
    this.sideColor = sideColor;
    this.size = size;
    this.camera = camera;
    this.onLoad = onLoad;
    this.randomizePosition = randomizePosition;

    if (startingPosition) {
      this.startingPosition = new Vector3(
        startingPosition[0],
        startingPosition[1],
        startingPosition[2]
      );
    } else {
      this.startingPosition = randomVector3();
    }

    this.buildMesh();

    this.amountToGrow = 0.01;
    this.minScale = 1;
    this.maxScale = 1.5;
    this.scale = this.minScale;

    if (this.randomizePosition) {
      window.setInterval(() => {
        this.mesh.position.copy(randomVector3());
      }, 4000);
    }
  }

  buildMesh() {
    const geometry = new TextGeometry(this.text, {
      font: this.font,
      size: this.size,
      height: 0.5,
      curveSegments: 3,
    });
    geometry.center();

    const materials = [
      new MeshBasicMaterial({ color: this.frontColor }),
      new MeshBasicMaterial({ color: this.sideColor }),
    ];

    this.mesh = new Mesh(geometry, materials);

    this.mesh.position.copy(this.startingPosition);
    this.onLoad(this);
  }

  animate() {
    if (!this.mesh) {
      return;
    }

    this.scale += this.amountToGrow;

    if (this.scale >= this.maxScale || this.scale <= this.minScale) {
      this.amountToGrow = -this.amountToGrow;
    }

    this.mesh.scale.set(this.scale, this.scale, this.scale);

    this.mesh.lookAt(this.camera.position);
  }
}

export default BulgingText;
